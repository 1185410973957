import React, { useRef } from 'react';
import emailjs, { init } from 'emailjs-com';
import { useHistory } from "react-router-dom";
import "./Contacto.css"
import mail from "../../img/mailInverse.png";
import phone from "../../img/phone.png";
import linkedin from "../../img/linkedin.png";
import instagram from "../../img/instagram.png";
import facebook from "../../img/facebook.png";


const Contacto = () => {
    init("user_OqUlsPwrHyb3Px1WoS5x1");
    const form = useRef();
    let history = useHistory();

    const sendEmail = (e) => {
        e.preventDefault();
        emailjs.sendForm('service_iq5sxrb', 'template_g9ll3qq', form.current, 'user_OqUlsPwrHyb3Px1WoS5x1').then(history.push("/gracias"));
    };

    return(
        <div className="contacto" id="contacto">
            <h1>Contacto</h1>
            <div className="formulario" >
                <span>
                    <b>ESCRIBINOS</b>
                    <p>Para que podamos ayudarte mejor, por favor completá el formulario y nos comunicaremos con vos muy pronto. ¡Gracias!</p>
                    <p><img src={phone} alt="Telefono" />+54 11 5689 5895</p>
                    <p><img src={mail} alt="Mail" />info@blockchainar.com.ar</p>
                    <div className="redes">
                        <a href='https://www.facebook.com'><img src={facebook} alt="Facebook" /></a>
                        <a href='https://www.instagram.com/ps.pass/'><img src={instagram} alt="Instagram" /></a>
                        <a href='https://www.linkedin.com'><img src={linkedin} alt="Linkedin" /></a>
                    </div>
                </span>
                <form ref={form} onSubmit={sendEmail}>
                    <input type="text" name="user_name" placeholder="Nombre" />
                    <input type="email" name="user_email" placeholder="Mail" />
                    <textarea name="message" placeholder="Escribe aquí" />
                    <input className="submit" type="submit" value="ENVIAR" />
                </form>
            </div>
        </div>
    )
}


export default Contacto