import './App.css';
import React from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import PasapContainer from "./containers/PasapContainer/PasapContainer";
import BlockChainContainer from "./containers/BlockChainContainer/BlockChainContainer";
import Gracias from './components/Gracias/Gracias';
import TecnologiaContainer from './containers/TecnologiaContainer/TecnologiaContainer';
import { FirstPage } from './pages/FirstPage';
import { SecondPage } from './pages/SecondPage';
import { ThirdPage } from './pages/ThirdPage';

function App() {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/gracias" component={() => <Gracias />} />
        <Route exact path="/pasap" component={() => <PasapContainer />} />
        <Route exact path="/tecno" component={() => <TecnologiaContainer />} />
        <Route exact path="/pasap/bienvenidos" component={() => <FirstPage /> } />
        <Route exact path="/pasap/onu" component={() => <SecondPage /> } />
        <Route exact path="/pasap/salud-infantil" component={() => <ThirdPage /> } />
        <Route exact path="" component={() => <BlockChainContainer />} />
        <Redirect to="/pasap-digital" />
      </Switch>
    </BrowserRouter>
  );
}


export default App;